import React, { useState } from 'react';

import RatingStars from 'components/directus/rating-stars/RatingStars';
import { timestamp } from 'utils/date-time';
import { initials } from 'utils/initials';
import { Props, DateProps } from './ReviewCentreGroupReview.interface';

import s from './ReviewCentreGroupReview.scss';

export default ({ userFullName, body, starScore, review_date, dateOfCreation }: Props): JSX.Element => {
    const [ expanded, setExpanded ] = useState<boolean>(false);
    const { publishedDate, updatedDate }: DateProps =
    typeof review_date === 'string' ? JSON.parse(review_date) :
        typeof review_date === 'object' ? review_date :
            '';
    const excerpt = body.split(' ');

    return (
        <div className={s.review}>
            <div className={s.review__avatar}>
                <span>{initials(userFullName)}</span>
            </div>
            <div className={s.review__content}>
                <h4 className={s.review__title}>{userFullName}</h4>
                <div className={s.review__sub}>
                    <RatingStars score={starScore} starDimension="18px" />
                    {review_date ? (
                        <span className={s.review__sub__date}>{timestamp.fromNow(updatedDate || publishedDate)}</span>
                    ) : (
                        <span className={s.review__sub__date}>{timestamp.fromNow(dateOfCreation)}</span>
                    )}
                </div>

                {body !== null && (
                    <p aria-expanded={expanded}>
                        <>
                            {expanded ? body : excerpt.splice(0, 20).join(' ')}
                            {excerpt.length > 0 && (
                                <div role="presentation" onClick={() => setExpanded(!expanded)} className={s.review__button}>
                                    {expanded ? 'Read Less' : '... Read More'}
                                </div>
                            )}
                        </>
                    </p>
                )}
            </div>
        </div>
    );
};
