import { graphql, useStaticQuery } from 'gatsby';
import {
    AllDirectusReviewCentreOverviewFaq,
    ReviewCentreOverviewFaqEdges,
} from './review-centre-overview-faq.interface';

export default (): ReviewCentreOverviewFaqEdges[] => {
    const { allDirectusReviewCentreOverviewFaq } = useStaticQuery<AllDirectusReviewCentreOverviewFaq>(graphql`
    query {
      allDirectusReviewCentreOverviewFaq(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            background_colour
            image {
              data {
                full_url
              }
              width
              height
              localFile {
                childImageSharp {
                  fixed(width: 400, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64
                  }
                }
              }
            }
            image_alt
            image_position
            title
            body
            user_name
            user_quote
            quote_background_colour
            quote_border_colour
          }
        }
      }
    }
  `);

    return allDirectusReviewCentreOverviewFaq.edges;
};
