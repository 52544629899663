import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

export const timestamp = {
    fromNow: (timestamp: string): string => {
        try {
            dayjs.extend(relativeTime);

            const dateNow = dayjs();
            const datePast = dayjs(timestamp);

            if (dateNow.isBefore(datePast) || dateNow.isSame(datePast)) {
                return 'Just now';
            }

            return datePast.fromNow();
        } catch (e) {
            return '';
        }
    },
};

export const generateYearsBetween = (startYear = 2015, endYear?: string): number[] => {
    const endDate = endYear || new Date().getFullYear();
    const years = [];
    while (startYear <= endDate) {
        years.push(startYear);
        // tslint:disable-next-line
        startYear += 1;
    }
    return years;
};
