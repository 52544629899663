import { graphql, useStaticQuery } from 'gatsby';
import { initials } from 'utils/initials';
import { AllDirectusReviewCentreReview } from './review-centre-review.interface';

export default (): any => {
    const { allDirectusReviewCentreReview, allTrustpilotReview, allGoogleReview } = useStaticQuery<AllDirectusReviewCentreReview>(graphql`
    query {
      allDirectusReviewCentreReview {
        edges {
          node {
            directusId
            review_company
            user_name
            score
            date_of_creation
            body
          }
        }
      }
      allTrustpilotReview(limit: 5) {
        edges {
          node {
            id
            reviewer
            stars
            title
            body
            domain
            review_date
            updated_at
            created_at
          }
        }
      }
      allGoogleReview(sort: { fields: date_utc, order: DESC }, limit: 5) {
        edges {
          node {
            id
            rating
            body
            date
            date_utc
            source
            created_at
            updated_at
            domain
          }
        }
      }
    }
  `);

    const reviewsByReviewCompany = (companyName: string) =>
        allDirectusReviewCentreReview.edges
            .filter(({ node }) => node.review_company === companyName)
            .map(({ node }) => ({
                directusId: node.directusId,
                reviewCompany: node.review_company,
                userName: node.user_name,
                userInitials: initials(node.user_name),
                score: node.score,
                dateOfCreation: node.date_of_creation,
                body: node.body,
            }));

    const google = allGoogleReview.edges.map(({ node }, i) => ({
        directusId: node.id,
        sort: i + 1,
        reviewCompany: 'Google',
        userName: node.source,
        userInitials: initials(node.source),
        score: node.rating,
        dateOfCreation: node.created_at,
        body: node.body,
        review_date: {
            publishedDate: node.date_utc,
        },
    }));

    const trustpilot = allTrustpilotReview.edges.map(({ node }, i) => ({
        directusId: node.id,
        sort: i + 1,
        reviewCompany: 'Trustpilot',
        userName: node.reviewer,
        userInitials: initials(node.reviewer),
        score: node.stars,
        dateOfCreation: node.created_at,
        title: node.title,
        body: node.body,
        review_date: node.review_date,
    }));

    return {
        trustpilot,
        freeindex: reviewsByReviewCompany('Freeindex'),
        yell: reviewsByReviewCompany('Yell'),
        google,
        facebook: reviewsByReviewCompany('Facebook'),
    };
};
