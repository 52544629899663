import React from 'react';
import slugify from 'react-slugify';

import Carousel, { Pane } from 'components/directus/carousel/Carousel';
import customerReviews from 'hooks/customer-reviews/customer-reviews.hook';
import { checkFloat } from 'utils/check-float';
import ReviewCentreOverviewFaq from './review-centre-overview-faq/ReviewCentreOverviewFaq';

import s from './ReviewCentreOverview.scss';

export default (): JSX.Element => {
    const stats = customerReviews();

    return (
        <>
            <div className={s.banner}>
                <h3 className={s.banner__title}>How we are rated</h3>
                <div className={s.carousel}>
                    <Carousel
                        width={300}
                        height={250}
                        maxVisibleSlides={5}
                        hasArrows
                        changeOnResize
                        dots={false}
                    >
                        {stats.map(({ node }) => (
                            <Pane key={`panel-${slugify(node.provider)}`}>
                                <span className="glider-slide">
                                    <a href={node.link} rel="noreferrer" target="_blank" className={s.pane}>
                                        <span className={s.pane__provider}>{node.provider}</span>
                                        <span className={s.pane__score}>
                                            {checkFloat(node.score) ? `${node.score}.0` : node.score}
                                        </span>
                                        <span className={s.pane__maxScore}>
                                            out of {checkFloat(node.max_score) ? `${node.max_score}.0` : node.max_score}
                                        </span>
                                        <span className={s.pane__totalReviews}>{node.total_reviews.toLocaleString()} reviews</span>
                                    </a>
                                </span>
                            </Pane>
                        ))}
                    </Carousel>
                </div>
            </div>

            <div>
                <ReviewCentreOverviewFaq />
            </div>
        </>
    );
};
