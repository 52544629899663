import React from 'react';
import slugify from 'react-slugify';
import Img from 'gatsby-image';

import Markdown from 'components/common/markdown/Markdown';
import Section from 'components/common/section/Section';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import RatingStars from 'components/directus/rating-stars/RatingStars';
import reviewCentreOverviewFaqHook from 'hooks/review-centre-overview-faq/review-centre-overview-faq.hook';
import { initials } from 'utils/initials';

import s from './ReviewCentreOverviewFaq.scss';

export default (): JSX.Element => {
    const faqs = reviewCentreOverviewFaqHook().sort();
    return (
        <>
            {faqs.map(({ node }, i: number) => (
                <Section
                    className={s.faq}
                    key={`review-centre-overview-faq-${slugify(node.title)}`}
                    colour={i % 2 === 0 ? 'white' : 'grey'}
                >
                    <Wrapper>
                        <div
                            className={`${s.faq__container} ${node.image_position === 'Right' ? s.faq__container___reverse : null}`}
                        >
                            <div className={s.faq__imageContainer}>
                                <Img
                                    fixed={node.image.localFile.childImageSharp.fixed}
                                    alt={node.image_alt}
                                    className={s.faq__imageContainer__image}
                                    fadeIn
                                />
                            </div>

                            <div className={s.faq__content}>
                                <h2 className={s.faq__title}>
                                    <Markdown source={node.title} />
                                </h2>
                                <p>
                                    <Markdown source={node.body} />
                                </p>

                                <div
                                    className={s.faq__quote}
                                    style={{ backgroundColor: node.quote_background_colour, borderColor: node.quote_border_colour }}
                                >
                                    <div className={s.faq__quote__avatar} style={{ backgroundColor: node.background_colour }}>
                                        <span>{initials(node.user_name)}</span>
                                    </div>
                                    <div>
                                        <RatingStars score={5} starDimension="21px" />
                                        <div className={s.faq__quote__content}>
                                            <p className={s.faqs__quote__quote}>{node.user_quote}</p>
                                        </div>
                                        <div className={s.faq__quote__user}>
                                            <span style={{ color: node.background_colour }}>{node.user_name}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Wrapper>
                </Section>
            ))}
        </>
    );
};
