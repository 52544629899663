import React, { useEffect, useRef, useState } from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import slugify from 'react-slugify';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { globalHistory } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

import reviewCentreReview from 'hooks/review-centre-review/review-centre-review.hook';
import ReviewCentreOverview from './review-centre-overview/ReviewCentreOverview';
import ReviewCentreGroup from './review-centre-group/ReviewCentreGroup';

import s from './ReviewCentre.scss';

export default (): JSX.Element => {
    const tabSpanRef = useRef(null);
    const [ activeTabIndex, setActiveTabIndex ] = useState<number>(0);
    const [ isMenuOpen, setIsMenuOpen ] = useState<boolean>(false);
    const reviews = reviewCentreReview();

    const tabs = [
        {
            name: 'Overview',
            component: <ReviewCentreOverview />,
        },
        {
            name: 'Google',
            component: <ReviewCentreGroup slug="google" reviews={reviews.google} />,
        },
        {
            name: 'Facebook',
            component: <ReviewCentreGroup slug="facebook" reviews={reviews.facebook} />,
        },
        {
            name: 'Trustpilot',
            component: <ReviewCentreGroup slug="trustpilot" reviews={reviews.trustpilot} />,
        },
        {
            name: 'Yell',
            component: <ReviewCentreGroup slug="yell" reviews={reviews.yell} />,
        },
        {
            name: 'FreeIndex',
            component: <ReviewCentreGroup slug="freeindex" reviews={reviews.freeindex} />,
        },
    ];

    const getTabIndex = (hash: string) => tabs.findIndex(({ name }) => name.toLowerCase() === hash);

    useEffect(() => {
        if (window && window.location.hash) {
            const hash = window.location.hash.substr(1).toLowerCase();
            setActiveTabIndex(getTabIndex(hash));
            scrollTo('body');
        }
    }, []);

    useEffect(() => globalHistory.listen(({ action }) => {
        if (action === 'PUSH' && window && window.location.hash) {
            const hash = window.location.hash.substr(1).toLowerCase();
            setActiveTabIndex(getTabIndex(hash));
            scrollTo('body');
        }
    }), [ activeTabIndex ]);

    const handleMenuButtonClick = () => {
        setIsMenuOpen(! isMenuOpen);
    };

    const selectTab = (i: number) => {
        const tabName = tabs[ i ].name;
        setActiveTabIndex(i);
        scrollTo('body');

        if (typeof window !== 'undefined' && window.history) {
            window.history.pushState(null, '', `#${tabName.toLowerCase()}`);
        }
    };

    return (
        <>
            <div className={s.tabs}>
                <Tabs selectedIndex={activeTabIndex} onSelect={i => selectTab(i)}>
                    <span ref={tabSpanRef} aria-hidden />

                    <div className={s.tabs__nav}>
                        <div className={s.tabs__nav__active}>
                            {tabs[ activeTabIndex ].name}
                        </div>

                        <div role="presentation" onClick={handleMenuButtonClick} className={s.tabs__nav__menuButton}>
                            <b>More</b>&nbsp;<FontAwesomeIcon icon={faCaretDown} />
                        </div>
                    </div>

                    <div className={s.tabs__list} style={{ display: isMenuOpen ? 'block' : 'none' }}>
                        <TabList>
                            {tabs.map((tab, i) => (
                                <Tab
                                    tabIndex={i.toString()}
                                    className={`${s.tabs__list__item} ${i === activeTabIndex && s.tabs__list__active}`}
                                    key={tab.name.toString()}
                                >
                                    {tab.name}
                                </Tab>
                            ))}
                        </TabList>
                    </div>

                    {tabs.map((tab, i) => (
                        <TabPanel tabIndex={i} key={`tab-$${slugify(tab.name)}i}`}>
                            {tab.component}
                        </TabPanel>
                    ))}
                </Tabs>
            </div>
        </>
    );
};
