import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Section from 'components/common/section/Section';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import CtaPanel from 'components/directus/cta-panel/CtaPanel';
import ReviewCentreIntro from 'components/directus/review-centre-intro/ReviewCentreIntro';
import { DirectusPageData } from 'interfaces/directus-page-data.interface';
import { getFieldBySlug } from 'utils/directus';
import { PageProps } from 'utils/gatsby.interface';
import ReviewCentre from '../components/directus/review-centre/ReviewCentre';

export default ({ data }: PageProps<DirectusPageData>): JSX.Element => {
    const { node } = getFieldBySlug('customer-reviews', data.allDirectusPage.edges);

    return (
        <>
            <Helmet>
                <title>{node.title}</title>
                <meta name="title" content={node.title} />
                <meta name="description" content={node.description} />
                <meta name="robots" content={node.robots} />
                <link rel="canonical" href={node.canonical} />
            </Helmet>

            <ReviewCentreIntro />
            <ReviewCentre />

            <Wrapper>
                <Section top={50} bottom={50}>
                    <CtaPanel slug="home-cta" />
                </Section>
            </Wrapper>
        </>
    );
};

export const query = graphql`
  query {
    allDirectusPage(filter: { slug: { eq: "customer-reviews" } }) {
      edges {
        node {
          title
          slug
          description
          robots
          canonical
        }
      }
    }
  }
`;
